import { defaultIfNilOrWhiteSpace, isObject, isString, type Nil } from "@mcwd/typescript-type-guards";
import { CountryLocale, ExtendedLocaleCountry, LanguageCountryLocale, SiteLocale } from "../../types-and-definitions/locale-defs/Locales.js";
import { type IValueGetter } from "./types-and-classes/IValueGetter.js";

import { getGeoLocationDataSync } from "../onetrust/getOneTrustValues.js";

type GeoLocationData = { countryCode: string, stateCode?: string | Nil };

export const GeoLocation = {
  get defaultValueIfEmpty() {
    return null;
  },
  calculateValue(): GeoLocationData | Nil {
    const otGeoLocData = getGeoLocationDataSync();
    const countryCode = otGeoLocData?.countryCode?.trim()?.toUpperCase();
    const stateCode = otGeoLocData?.stateCode?.trim()?.toUpperCase();
    if (isString(countryCode) && countryCode !== "") {
      if (isString(stateCode) && stateCode !== "") {
        return { countryCode, stateCode };
      }
      else {
        return { countryCode };
      }
    }
    else {
      return null;
    }
  },
  get value() {
    return defaultIfNilOrWhiteSpace(
      this.calculateValue(),
      { defaultValue: this.defaultValueIfEmpty }
    );
  }
} as const satisfies IValueGetter<GeoLocationData | Nil, GeoLocationData | Nil, GeoLocationData | Nil>;

export const BrowserPreferredLanguage = {
  get defaultValueIfEmpty() {
    return null;
  },
  calculateValue(): LanguageCountryLocale | Nil {
    const acceptedLangs = LanguageCountryLocale;
    if ("languages" in navigator) {
      const langs = navigator.languages.filter(l => {
        return (acceptedLangs as readonly string[]).includes(l);
      });
      // If matching multiple languages, no inference can be made, so only return a value if a single language is matched.
      if (langs.length === 1) {
        return langs.shift() as LanguageCountryLocale;
      }
    }
    // Return the primary language if it matches
    if ("language" in navigator && (acceptedLangs as readonly string[]).includes(navigator.language)) {
      return navigator.language as LanguageCountryLocale;
    }
    return null;
  },
  get value() {
    return defaultIfNilOrWhiteSpace(
      this.calculateValue(),
      { defaultValue: this.defaultValueIfEmpty }
    );
  }
} as const satisfies IValueGetter<LanguageCountryLocale | Nil, LanguageCountryLocale | Nil, LanguageCountryLocale | Nil>;



export const InferredSiteLang =  {
  get defaultValueIfEmpty() {
    return null;
  },

  calculateFromOtGeoQuerystring() {
    let otgeo = (new URLSearchParams(window.location.search)).get("otgeo");
    if (isString(otgeo)) {
      otgeo = (otgeo.toLowerCase() === "uk" ? "gb" : otgeo);
      let sLocale = SiteLocale.getByCountryLocale(otgeo as CountryLocale);
      sLocale ??= SiteLocale.getByExtendedCountryLocale(otgeo as ExtendedLocaleCountry);
      if (isObject(sLocale)) {
        return sLocale.LanguageCountryLocale;
      }
    }
    return null;
  },
  calculateFromGeoLocation() {
    const geoLocation = GeoLocation.value;
    if (isObject(geoLocation) && isString(geoLocation.countryCode) && geoLocation.countryCode.trim() !== "") {
      const sLocale = SiteLocale.getByCountryLocale(geoLocation.countryCode as CountryLocale);
      if (isObject(sLocale)) {
        return sLocale.LanguageCountryLocale;
      }
    }
    return null;
  },
  calculateFromGeoLocationExtendedRegion() {
    const geoLocation = GeoLocation.value;
    if (isObject(geoLocation) && isString(geoLocation.countryCode) && geoLocation.countryCode.trim() !== "") {
      return SiteLocale.getByExtendedCountryLocale(geoLocation.countryCode as ExtendedLocaleCountry)?.LanguageCountryLocale ?? null;
    }
    return null;
  },
  calculateFromBrowserLanguage(): LanguageCountryLocale | Nil {
    return BrowserPreferredLanguage.value;
  },
  calculateValue(): LanguageCountryLocale | Nil {
    return defaultIfNilOrWhiteSpace(
      this.calculateFromOtGeoQuerystring(),
      { getter: () => this.calculateFromGeoLocation() },
      { getter: () => this.calculateFromBrowserLanguage() },
      { getter: () => this.calculateFromGeoLocationExtendedRegion() }
    );
  },
  get value() {
    return defaultIfNilOrWhiteSpace(
      this.calculateValue(),
      { defaultValue: this.defaultValueIfEmpty }
    );
  }
} as const satisfies {
  calculateFromOtGeoQuerystring(): LanguageCountryLocale | Nil;
  calculateFromGeoLocation(): LanguageCountryLocale | Nil;
  calculateFromGeoLocationExtendedRegion(): LanguageCountryLocale | Nil;
  calculateFromBrowserLanguage(): LanguageCountryLocale | Nil;
} & IValueGetter<LanguageCountryLocale | Nil, LanguageCountryLocale | Nil, LanguageCountryLocale | Nil>;
