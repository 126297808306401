import { isNotNil, isObject, isString } from "@mcwd/typescript-type-guards";
import type { LoggerCss } from "./LoggerCss.js";
import { ConsoleLoggingLevels } from "./WindowConsole.js";

export function getStringFromStyleObject(stylesObj: LoggerCss) {
    let cssStyles = "";

    for (const styleProp of Object.keys(stylesObj)) {
        cssStyles += `${styleProp}: ${stylesObj[styleProp] as string};`;
    }
    return cssStyles;
}

export const BasicButtonLabelStyle = Object.freeze({
    color: "white",
    "font-size": "10px",
    "line-height": "10px",
    "font-family": "'Helvetica', 'Arial', sans-serif",
    padding: "3px",
    "margin-right": "5px",
    "border-radius": "3px",
    border: "1px solid black",
} as const) satisfies LoggerCss;

const LoggerLevelColors = {
    info: "#40F99B",
    debug: "#6EF9F5",
    warn: "#FBB13C",
    error: "#D64933"
}  as const satisfies Record<ConsoleLoggingLevels, string>;

const LoggerLevelLabelStyle: LoggerCss = Object.freeze({
    "border-radius": "3px",
    "background": "#282828",
    "font-family": `"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace`,
    "font-size": "10px",
    "padding": "2px",
    "margin-right": "5px"
});

export function getLevelLabelStyle(level: ConsoleLoggingLevels): string {
    const color = LoggerLevelColors[level] ?? "white";
    return getStringFromStyleObject({ ... LoggerLevelLabelStyle, color });
}

export function getStyledMessageArgs({ level, messages, styles, formattingSuffix }: {
    level: ConsoleLoggingLevels,
    messages: string[],
    styles: LoggerCss[],
    formattingSuffix?: string
}): string[]
{
    formattingSuffix ??= "";
    let firstArg = "";
    const remainingArgsArr: string[] = [];

    if (ConsoleLoggingLevels.includes(level)) {
        firstArg += "%c%s";
        remainingArgsArr.push(getLevelLabelStyle(level));
        remainingArgsArr.push(level);
    }

    const argLengthDifference = messages.length - styles.length;
    if (argLengthDifference < -1) {
        throw new Error("Too many LoggerCss objects provided");
    }
    
    const maxMessagesOrStyles = Math.max(messages.length, styles.length);
    for (let i = 0; i < maxMessagesOrStyles; i++) {
        const msg = (messages.length >= i) ? messages[i] : null;
        const styleObj = (styles.length >= i) ? styles[i] : null;
        const styleCss = isObject(styleObj) ? getStringFromStyleObject(styleObj) : null;
        if (isNotNil(styleCss)) {
            firstArg += "%c";
            remainingArgsArr.push(styleCss);
        }
        if (isString(msg)) {
            firstArg += "%s";
            remainingArgsArr.push(msg);
        }
    }
    return [firstArg + formattingSuffix, ...remainingArgsArr];
}