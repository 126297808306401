import { addLoggerFilterOverride } from "../../utils/Logger/LoggerTags.js";
import { Logger } from "../../utils/Logger/Logger.js";

const logger_otLabelBackgroundColor = "#8C7284";

export const OneTrustVerboseTag = "OneTrustConsent";

export const onetrustLogger = new Logger({
  tags: { category: "OneTrust" },
  prefixStrings: ["OneTrust"],
  prefixStyles: [Logger.StylePresets.ButtonLabel({ 'background-color': logger_otLabelBackgroundColor })]
});

export const onetrustVerboseLogger = onetrustLogger.withTags({ verboseDebug: OneTrustVerboseTag });


addLoggerFilterOverride({
  verboseDebug: [OneTrustVerboseTag]
}, false);