import { isFunction } from "@mcwd/typescript-type-guards";

export function getExternalReferrer () {
  const [{ referrer = "" }, { protocol, host }] = [document, location];
  const referrerIsExternalDomain = (referrer !== "") && referrer.indexOf(protocol + "//" + host) !== 0;
  return referrerIsExternalDomain ? referrer : "";
}

export function getExternalReferrerDomainOnly () {
  const referrer = getExternalReferrer();
  if (referrer === "") {
    return "";
  }

  if (URL && ("canParse" in URL) && isFunction(URL.canParse) && URL.canParse(referrer)) {
    return (new URL(referrer)).origin;
  }
  else {
    try { return new URL(referrer).origin; }
    catch { /* do nothing */ }
  }
  return "";
}