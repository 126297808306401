// The type definitions for this file are purposefully updated by hand in types.d.ts

let objToString = (value) => {
    return Object.prototype.toString.call(value);
};

export function isNull (value) {
    return value === null;
}

export function isUndefined(value) {
    // eslint-disable-next-line no-undefined
    return value === undefined;
}

export function isNil (value) {
    return (value ?? null) === null;
}

export function isNotNil(value) {
    return isNil(value) === false;
}

export function isString (value) {
    return isNotNil(value) && (typeof value === 'string' || objToString(value) === '[object String]');
}

export function isSymbol(value) {
    return typeof value === "symbol";
}

export function isObject (value) {
    return isNotNil(value) && (typeof value === 'object');
}


export function isFunction(value) {
    return isNotNil(value) && ((typeof value === 'function') || (objToString(value) === '[object Function]') || (value instanceof Function));
}

export function isRegExp (value) {
    return isNotNil(value) && (objToString(value) === '[object RegExp]' || (value instanceof RegExp));
}

export function isBoolean (value) {
    return isNotNil(value) && (objToString(value) === '[object Boolean]' || (value instanceof Boolean));
}

export function isNumber(value) {
    return typeof value === 'number' || (isObject(value) && objToString(value) === '[object Number]');
}

export function hasPropertyWithType(value, prop, typeCheckFn) {
    return isObject(value) && (prop in value) && typeCheckFn(value[prop]);
}


export function isPropertyKey(value) {
    return isNotNil(value) && (isString(value) || isNumber(value) || isSymbol(value));
}