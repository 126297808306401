import { isString, type Nil } from "@mcwd/typescript-type-guards";
import { getPageName } from "../page-data/getPageName.js";
import { CookiePersistedQueryStringValue } from "./types-and-classes/CookiePersistedQueryStringValue.js";
import { GenericCookiePersistedValue } from "./types-and-classes/GenericCookiePersistedValue.js";
import { getExternalReferrer, getExternalReferrerDomainOnly } from "./functions/getExternalReferrer.js";
import { getGclidQueryParameter } from "./functions/getGclid.js";

export const UtmCampaign = new CookiePersistedQueryStringValue({ queryName: "utm_campaign", cookieName: "utm_campaign" });
export const UtmMedium = new CookiePersistedQueryStringValue({ queryName: "utm_medium", cookieName: "utm_medium" });
export const UtmSource = new CookiePersistedQueryStringValue({ queryName: "utm_source", cookieName: "utm_source" });

export const EntryPage = new GenericCookiePersistedValue<string, string, string>({
  cookieName: "ENTRYPAGE",
  readCookieBeforeCalculating: true,
  valueGetter: () => document.location.pathname,
  defaultValue: ""
});

export const ExternalReferrer = new GenericCookiePersistedValue({
  cookieName: 'source_string',
  readCookieBeforeCalculating: false,
  valueGetter: getExternalReferrer,
  defaultValue: ""
});

export const ExternalReferrerDomainOnly = new GenericCookiePersistedValue({
  cookieName: ExternalReferrer.CookieName,
  readCookieBeforeCalculating: false,
  valueGetter: getExternalReferrerDomainOnly,
  defaultValue: ""
});

export const Gclid = new GenericCookiePersistedValue<string, string | Nil, string>({
  cookieName: 'gclid',
  readCookieBeforeCalculating: false,
  cookieReadOptions: {
    parse: (value) => {
      try {
        const val = JSON.parse(value.trim()) as unknown;
        return (isString(val) && val.trim() !== "") ? val.trim() : null;
      }
      catch {
        return "";
      }
    }
  },
  valueGetter: getGclidQueryParameter,
  defaultValue: ""
});

export const PageName = new GenericCookiePersistedValue<string, string, string>({
  cookieName: 'PAGENAME',
  readCookieBeforeCalculating: false,
  valueGetter: getPageName,
  defaultValue: ""
});