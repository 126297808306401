import { type Nil } from "@mcwd/typescript-type-guards";
import { getQueryParameter } from "../../../utils/queryParamUtils.js";

export function getGclidQueryParameter(): string | Nil {
  const gclsrcParam = getQueryParameter('gclsrc');
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;
  if (!isGclsrcValid) {
    return null;
  }
  return getQueryParameter('gclid');
}
