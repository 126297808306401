import { getCookie, type CookieReadOptions, type CookieValueType } from "../../../utils/cookieUtils.js";
import { defaultIfNilOrWhiteSpace, isUndefined, type Nil } from "@mcwd/typescript-type-guards";
import { type IValueGetter } from "./IValueGetter.js";

export abstract class CookiePersistedValue<T extends CookieValueType = string, TCalculate extends T | (T|Nil) = T | Nil, TDefault extends T | undefined = undefined> implements IValueGetter<T, TCalculate, TDefault> {
  protected __value: T | Nil;
  protected __readCookieBeforeCalculating: boolean;
  #cookieName: string;
  #cookieReadOptions: CookieReadOptions<T> | Nil;
  #defaultValue: TDefault;
  constructor({ cookieName, cookieReadOptions = null, readCookieBeforeCalculating, defaultValue }: ({ cookieName: string, cookieReadOptions?: CookieReadOptions<T> | Nil, readCookieBeforeCalculating: boolean, defaultValue: TDefault })) {
    this.#cookieName = cookieName;
    this.#cookieReadOptions = cookieReadOptions;
    this.__value = null;
    this.__readCookieBeforeCalculating = readCookieBeforeCalculating;
    this.#defaultValue = defaultValue;
  }

  get CookieName() {
    return this.#cookieName;
  }

  getValueFromCookie() {
    return getCookie<T>(this.#cookieName, this.#cookieReadOptions ?? undefined);
  }

  get defaultValueIfEmpty() {
    return this.#defaultValue;
  }

  abstract calculateValue(): TCalculate;

  protected getAllValueGetters(): Array<({ getter: () => T | Nil }) | { getter: () => TCalculate } | { defaultValue: TDefault }> {
    const defaultValueOrEmptyArray = ((isUndefined(this.#defaultValue) === false) ? [{ defaultValue: this.#defaultValue }] : []);
    const calculatedGetter = { getter: () => this.calculateValue() };
    const cookieGetter = { getter: () => this.getValueFromCookie() };
    if (this.__readCookieBeforeCalculating) {
      return [ cookieGetter, calculatedGetter, ... defaultValueOrEmptyArray ];
    }
    else {
      return [ calculatedGetter, cookieGetter, ... defaultValueOrEmptyArray ];
    }
  }

  get value(): TCalculate extends Nil ? (TDefault extends undefined ? TCalculate : TDefault) : TCalculate {
    const val = defaultIfNilOrWhiteSpace<T | Nil>(
      this.__value,
      ... this.getAllValueGetters()
    );
    this.__value = val;
    return val as (TCalculate extends Nil ? (TDefault extends undefined ? TCalculate : TDefault) : TCalculate);
  }
}