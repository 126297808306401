import { convertArrayToPropIndexedObject, flatMapArrayToNestedProp, type ConvertArrayToPropIndexedObject, type FlatMapArrayToNestedProp } from "@mcwd/typescript-type-guards";
import { LocaleSettingsArray } from "../locale-settings.js";


export type ExtendedLocales = FlatMapArrayToNestedProp<LocaleSettingsArray, ["ExtendedCountryLocales"]>;
export const ExtendedLocales = flatMapArrayToNestedProp(LocaleSettingsArray, ["ExtendedCountryLocales"] as const);

export type ExtendedLocaleSettingsObject = ConvertArrayToPropIndexedObject<ExtendedLocales, "CountryLocale">;
export const ExtendedLocaleSettingsObject = convertArrayToPropIndexedObject(ExtendedLocales, "CountryLocale");


export type ExtendedLocaleCountry = ExtendedLocales[number]["CountryLocale"];
export const ExtendedLocaleCountry = flatMapArrayToNestedProp(ExtendedLocales, ["CountryLocale"]);


// type buildExtendedLocaleOnlySplitObjects<TSettingsArray extends ICountryLocale[], TResult extends any[] = []> 
//   = 
// type buildExtendedLocaleOnlySettings<TSettingsArray extends ICountryLocale[], >

export type ExtendedLocaleOnlySettings = FlatMapArrayToNestedProp<LocaleSettingsArray, ["ExtendedCountryLocales"]>;
export const ExtendedLocaleOnlySettings = flatMapArrayToNestedProp(LocaleSettingsArray, ["ExtendedCountryLocales"] as const);