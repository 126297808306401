import type { ICountryLocale } from "./ICountryLocale.js";

export function createCountryLocale<
  const TCountryName extends string,
  const TCountryLocale extends string
>({ countryName, countryLocale }: { countryName: TCountryName, countryLocale: TCountryLocale }) {
  const locale = {
    CountryName: countryName,
    CountryLocale: countryLocale,
    CountryLocaleUppercase: countryLocale.toUpperCase() as Uppercase<TCountryLocale>,
  } as const satisfies ICountryLocale<TCountryName, TCountryLocale>;
  Object.freeze(locale);

  return locale;
}