import * as _CookieValueGetters from "./cookie-persisted-value-getters.js";
import * as _UserPreferenceValueGetters from "./user-preference-value-getters.js";

const CookieValueGetters = { ..._CookieValueGetters };
type CookieValueGetters = typeof CookieValueGetters;

const UserPreferenceValueGetters = { ..._UserPreferenceValueGetters };
type UserPreferenceValueGetters = typeof UserPreferenceValueGetters; 

export const ValueGetters = Object.freeze({
  ...CookieValueGetters,
  ...UserPreferenceValueGetters
});

(window as any).__Mc_ValueGetters = ValueGetters;