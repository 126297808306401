import type { ICountryLocale } from "./ICountryLocale.js";
import type { ISiteLocale, UrlPrefixType } from "./ISiteLocale.js";
import { createLanguageCountryLocale } from "./createLanguageCountryLocale.js";


export function createSiteLocale<
  const TSiteCountryCode extends string,
  const TLanguageName extends string,
  const TLanguageLocale extends string,
  const TCountryName extends string,
  const TCountryLocale extends string,
  const TExtendedCountryLocales extends [] | [ICountryLocale, ...ICountryLocale[]]
>({
  siteCountryCode,
  languageLocale,
  languageName,
  countryLocale,
  countryName,
}:
{
  siteCountryCode: TSiteCountryCode,
  languageLocale: TLanguageLocale,
  languageName: TLanguageName,
  countryLocale: TCountryLocale,
  countryName: TCountryName,
}, ...extendedCountryLocales: TExtendedCountryLocales)
{
  const urlPrefix = (
    siteCountryCode === "us"
      ? ""
      : `/${siteCountryCode}`
  ) as UrlPrefixType<TSiteCountryCode>;

  type TUrlPrefix = typeof urlPrefix;


  Object.freeze(extendedCountryLocales);


  const siteLocale = {
    ...createLanguageCountryLocale({ languageName, languageLocale, countryName, countryLocale }),
    SiteCountryCode: siteCountryCode,
    SiteCountryCodeUppercase: siteCountryCode.toUpperCase() as Uppercase<TSiteCountryCode>,
    UrlPrefix: urlPrefix,
    ExtendedCountryLocales: extendedCountryLocales
  } as const satisfies ISiteLocale<
    TSiteCountryCode,
    TLanguageName,
    TLanguageLocale,
    TCountryName,
    TCountryLocale,
    TUrlPrefix,
    TExtendedCountryLocales
  >;
  
  Object.freeze(siteLocale);
  return siteLocale;
}