import { isArray, isNil, isObject, isString, type Nil } from "@mcwd/typescript-type-guards";
import { readOneTrustCookie, readOneTrustCookieSync } from "./readOneTrustCookie.js";
import { OneTrustCategoryId, OneTrustCategoryIdToNameMapping } from "./OneTrustCategoryIdToNameMapping.js";
import type { OneTrustGroupData, OptanonConsentCookieObject } from "./OptanonContentCookieTypes.js";
import type { CookieGroupOTMeta, GoogleConsentOTMeta } from "./OneTrust.js";

import { onetrustVerboseLogger as verboseLogger } from "./oneTrustLogger.js";

function parseOnetrustActiveGroups(activeGroupsStr: string): OneTrustGroupData[] | Nil {
  if (!isString(activeGroupsStr)) {
    return null;
  }
  const activeGroupsList = activeGroupsStr.replace(/^,/, "").replace(/,$/, "").split(/,/);

  const groupsArr: OneTrustGroupData[] = [];

  for (const id of OneTrustCategoryId) {
    const name = OneTrustCategoryIdToNameMapping[id];
    groupsArr.push({ id, name, enabled: activeGroupsList.includes(id) });
  }

  const groupsNotInMapping = activeGroupsList.filter(g => (OneTrustCategoryId as readonly string[]).includes(g) === false);
  for (const unknownId of groupsNotInMapping) {
    groupsArr.push({ id: unknownId, enabled: true });
  }
  return groupsArr;
}

function getOneTrustActiveGroups(): OneTrustGroupData[] | Nil {
  const activeGroups = window.OnetrustActiveGroups;
  if (isString(activeGroups)) {
    const groupsVal = parseOnetrustActiveGroups(activeGroups);
    if (isArray(groupsVal)) {
      return groupsVal;
    }
  }
  else {
    verboseLogger.debug("OnetrustActiveGroups not set");
  }
  return null;
}

export interface OneTrustValues extends OptanonConsentCookieObject {
  ConsentModel?: { Name?: 'notice only' | 'opt-in' | 'opt-out' | 'implied consent' | 'custom' | unknown } | Nil;
  GoogleConsent?: GoogleConsentOTMeta | Nil;
  GroupsMeta?: CookieGroupOTMeta[] | Nil;
  IsBannerLoaded?: boolean | Nil;
  IsConsentLoggingEnabled?: boolean | Nil;
  IsIABEnabled?: boolean | Nil;
  Language?: { Culture: string } | Nil;
  NoBanner?: boolean | Nil;
}

function getOneTrustSdkGeolocation(): OneTrustValues["geolocation"] {
  const oneTrustSdk = window.OneTrust;

  if (isObject(oneTrustSdk)) {
    const geoResp = oneTrustSdk.geolocationResponse;
    if (isObject(geoResp) && isString(geoResp.countryCode) && geoResp.countryCode.trim() !== "") {
      return geoResp;
    }
    else {
      const geo = oneTrustSdk.getGeolocationData?.();
      if (isObject(geo) && isString(geo.country) && geo.country.trim().length > 0) {
        return { countryCode: geo.country, stateCode: geo.state };
      }
    }
  }
  return null;
}

function getOneTrustSdkValues(): OneTrustValues | Nil {
  const oneTrustSdk = window.OneTrust;
  const otValues: OneTrustValues | Nil = {};
  
  const otActiveGroups = getOneTrustActiveGroups();
  if (isArray(otActiveGroups)) {
    otValues.groups = otActiveGroups;
  }

  if (isObject(oneTrustSdk)) {
    const geoResp = getOneTrustSdkGeolocation();
    if (isObject(geoResp)) {
      otValues.geolocation = geoResp;
    }
    const domainData = oneTrustSdk.GetDomainData?.();
    if (isObject(domainData)) {
      const { ConsentModel, GoogleConsent, Groups, IsBannerLoaded, IsConsentLoggingEnabled, IsIABEnabled, Language, NoBanner } = domainData;
      Object.assign<OneTrustValues, OneTrustValues>(otValues, { ConsentModel, GoogleConsent, GroupsMeta: Groups, IsBannerLoaded, IsConsentLoggingEnabled, IsIABEnabled, Language, NoBanner });
    }
  }
  return (Object.keys(otValues).length === 0) ? null : otValues;
}



export async function getOneTrustValues() {
  const optanonCookieValues = await readOneTrustCookie();
  const otSdkVals = getOneTrustSdkValues();

  if (isNil(otSdkVals)) {
    if (isNil(optanonCookieValues)) {
      return null;
    }
    return { ... optanonCookieValues };
  }
  if (isArray(otSdkVals.groups)) {
    verboseLogger.debug("Use updated ot sdk groups value: ", { cookie: optanonCookieValues?.groups, sdk: otSdkVals.groups });
  }
  else {
    verboseLogger.debug("Use original optanon cookie groups value: ", { cookie: optanonCookieValues?.groups });
  }
  verboseLogger.debug("Merge cookie and updated ot sdk values: ", { cookie: optanonCookieValues, sdk: otSdkVals });
  return { ...(optanonCookieValues ?? {}), ... otSdkVals };
}

export function getGeoLocationDataSync() {
  const sdkGeo = getOneTrustSdkGeolocation();
  if (isObject(sdkGeo)) {
    return sdkGeo;
  }
  else {
    const optanonCookieValues = readOneTrustCookieSync();
    if (isObject(optanonCookieValues)
      && isObject(optanonCookieValues.geolocation)
      && isString(optanonCookieValues.geolocation.countryCode)
      && (optanonCookieValues.geolocation.countryCode.trim() !== ""))
    {
      return optanonCookieValues.geolocation as { countryCode: string, stateCode?: string | Nil };
    }
  }
  return null;
}

Object.assign(window, {
  getOneTrustActiveGroups,
  parseOnetrustActiveGroups,
  readOneTrustCookie,
  getOneTrustSdkValues,
  getOneTrustValues
});