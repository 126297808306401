import { getValueFromPropsPath } from "../nested-props.js";

export function mapArrayToProp(array, prop) {
  return array.map((el) => {
    return el[prop];
  });
}

export function flatMapArrayToProp(array, prop) {
  return array.flatMap((el) => {
    return el[prop];
  });
}

export function mapArrayToNestedProp(array, path, defaultIfNever) {
  return array.map((el) => {
    return getValueFromPropsPath(el, path, defaultIfNever);
  });
}

export function flatMapArrayToNestedProp(array, path, defaultIfNever) {
  return array.flatMap((el) => {
    return getValueFromPropsPath(el, path, defaultIfNever);
  });
}