import { isSiteLocaleWithExtendedCountryLocales, type ISiteLocale } from "./create/ISiteLocale.js";
import { createSiteLocale } from "./create/createSiteLocale.js";
import { createCountryLocale } from "./create/createCountryLocale.js";

import { convertArrayToPropIndexedObject, type ConvertArrayToPropIndexedObject } from "@mcwd/typescript-type-guards";
import type { BuildOnlyLocaleSettingsWithExtendedCountryLocales_Array } from "./create/BuildOnlyLocaleSettingsWithExtendedCountryLocales_Array.js";

const Additional_APAC = [
  createCountryLocale({ countryName: "India", countryLocale: "in" }),
  createCountryLocale({ countryName: "Singapore", countryLocale: "sg" }),
  createCountryLocale({ countryName: "Malaysia", countryLocale: "my" }),
  createCountryLocale({ countryName: "Pakistan", countryLocale: "pk" }),
  createCountryLocale({ countryName: "Bangladesh", countryLocale: "bd" }),
  createCountryLocale({ countryName: "New Zealand", countryLocale: "nz" }),
  createCountryLocale({ countryName: "Vietnam", countryLocale: "vn" }),
  createCountryLocale({ countryName: "Thailand", countryLocale: "th" }),
] as const;

const Additional_EMEA = [
  createCountryLocale({ countryName: "Germany", countryLocale: "de" }),
  createCountryLocale({ countryName: "Sweden", countryLocale: "se" }),
  createCountryLocale({ countryName: "Denmark", countryLocale: "dk" }),
  createCountryLocale({ countryName: "Belgium", countryLocale: "be" }),
  createCountryLocale({ countryName: "Ireland", countryLocale: "ie" }),
  createCountryLocale({ countryName: "Italy", countryLocale: "it" }),
  createCountryLocale({ countryName: "Spain", countryLocale: "es" }),
  createCountryLocale({ countryName: "Poland", countryLocale: "pl" }),
  createCountryLocale({ countryName: "Portugal", countryLocale: "pt" }),
  createCountryLocale({ countryName: "Austria", countryLocale: "at" }),
  createCountryLocale({ countryName: "Norway", countryLocale: "no" }),
  createCountryLocale({ countryName: "Romania", countryLocale: "ro" }),
  createCountryLocale({ countryName: "Greece", countryLocale: "gr" }),
  createCountryLocale({ countryName: "Hungary", countryLocale: "hu" }),
  createCountryLocale({ countryName: "Czechia", countryLocale: "cz" }),
  createCountryLocale({ countryName: "Luxembourg", countryLocale: "lu" }),
  createCountryLocale({ countryName: "Croatia", countryLocale: "hr" }),
  createCountryLocale({ countryName: "Malta", countryLocale: "mt" }),
  createCountryLocale({ countryName: "Slovakia", countryLocale: "sk" }),
] as const;

export const LocaleSettingsArray = [
  createSiteLocale({
    siteCountryCode: "us",
    languageName: "English",
    languageLocale: "en",
    countryName: "United States",
    countryLocale: "us",
  }),
  createSiteLocale({
    siteCountryCode: "uk",
    languageName: "English",
    languageLocale: "en",
    countryName: "United Kingdom",
    countryLocale: "gb",
  }, ...Additional_EMEA),
  createSiteLocale({
    siteCountryCode: "au",
    languageName: "English",
    languageLocale: "en",
    countryName: "Australia",
    countryLocale: "au",
  }, ...Additional_APAC),
  createSiteLocale({
    siteCountryCode: "fr",
    languageName: "French",
    languageLocale: "fr",
    countryName: "France",
    countryLocale: "fr",
  }),
  createSiteLocale({
    siteCountryCode: "jp",
    languageName: "Japanese",
    languageLocale: "ja",
    countryName: "Japan",
    countryLocale: "jp",
  }),
  createSiteLocale({
    siteCountryCode: "kr",
    languageName: "Korean",
    languageLocale: "ko",
    countryName: "Korea",
    countryLocale: "kr",
  }),
  createSiteLocale({
    siteCountryCode: "cn",
    languageName: "Chinese (Simplified)",
    languageLocale: "zh",
    countryName: "China",
    countryLocale: "cn",
  }),
] as const satisfies ISiteLocale[];

export type LocaleSettingsArray = typeof LocaleSettingsArray;


export type SiteLocaleWithExtendedCountryLocaleArray = BuildOnlyLocaleSettingsWithExtendedCountryLocales_Array<LocaleSettingsArray>;
export const SiteLocaleWithExtendedCountryLocaleArray = LocaleSettingsArray.filter(isSiteLocaleWithExtendedCountryLocales) as SiteLocaleWithExtendedCountryLocaleArray;


export const SiteLocaleSettings = convertArrayToPropIndexedObject(LocaleSettingsArray, "SiteCountryCode");
export type SiteLocaleSettings = ConvertArrayToPropIndexedObject<LocaleSettingsArray, "SiteCountryCode">;



Object.freeze(LocaleSettingsArray);
Object.freeze(SiteLocaleSettings);
