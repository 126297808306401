import { isBoolean, isEmptyString, isFunction, isNil, isNumber, isString, type Nil } from "@mcwd/typescript-type-guards";
import { 
  getCookie as libGetCookie, 
  setCookie as libSetCookie, 
  removeCookie as libRemoveCookie,
  type Types as CookieTypes
} from "typescript-cookie";


export type CookieValueType = string | number | boolean | object;

export type CookieSameSite = Exclude<CookieTypes.CookieAttributes["sameSite"], undefined>;

/** 
 * The attributes that can be set on a browser cookie 
 * @property {string?} path
 * @property {string?} domain
 * @property {(number | Date)?} expires
 * @property {CookieSameSite?} sameSite
 * @property {boolean?} sameSite
 */
export interface CookieAttributes extends CookieTypes.CookieAttributes {
  sameSite?: CookieSameSite;
}

function getAttributesWithDefaults(attributes?: CookieAttributes){
  // Set defaults
  attributes ??= {};
  attributes.domain ??= window.location.host.replace(/^localhost:\d+$/, "localhost");
  attributes.path ??= "/";
  return attributes;
}

/**
 * Deletes the specified browser cookie
 * @param {string} name - the name of the cookie to delete
 * @param {CookieAttributes?} attributes - the cookie attributes to use for deletion. These must match what was used to set the cookie except "expires"
 */
export function removeCookie(name: string, attributes?: CookieAttributes | undefined) {
  return libRemoveCookie(name, getAttributesWithDefaults(attributes));
}

export type ParseCookieOpt<T> = boolean | ((value: string) => T | Nil);

export type CookieReadOptions<T extends CookieValueType = string> = {
  defaultValue?: (T | Nil),
  parse?: (((value: string) => T | Nil) | Nil) | boolean
};

/**
 * Get the value of the specified browser cookie
 * @param {string} name: the cookie name to get
 * @param {object?} options
 * @param {any} options.defaultValue: a default value when the actual value is null or undefined.
 * @param {boolean} options.parse: whether or not to JSON.parse the value before returning.
 */
export function getCookie<T extends CookieValueType = string>(name: string, options: CookieReadOptions<T> = {}): T | Nil {
  const { defaultValue, parse } = options;

  const strValue: string | undefined = libGetCookie(name)?.trim();
  if (isNil(strValue) || isEmptyString(strValue)){
    return defaultValue;
  }
  else if (isBoolean(parse)) {
    try {
      return (JSON.parse(strValue) as T | Nil) ?? defaultValue;
    }
    catch (err) {
      console.log(err);
    }
  }
  else if (isFunction(parse)) {
    return parse(strValue) ?? defaultValue;
  }
  return strValue as T;
}

/**
 * Sets the specified browser cookie value
 * @param {string} name - the name of the cookie to delete
 * @param {CookieAttributes?} attributes - the cookie attributes to use for deletion. These must match what was used to set the cookie except "expires"
 */
export function setCookie<T extends string | number | boolean | Nil | object>(
  name: string, 
  value: T, 
  attributes?: CookieAttributes
) {
  if (isNil(value)){
    return removeCookie(name, attributes);
  }
  else if (isString(value) || isBoolean(value) || isNumber(value)) {
    return libSetCookie(name, value, getAttributesWithDefaults(attributes));
  }
  else {
    return libSetCookie(name, JSON.stringify(value), getAttributesWithDefaults(attributes));
  }
}