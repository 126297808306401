import { type CookieReadOptions } from "../../../utils/cookieUtils.js";
import { getQueryParameter } from "../../../utils/queryParamUtils.js";
import { type Nil } from "@mcwd/typescript-type-guards";

import { CookiePersistedValue } from "./CookiePersistedValue.js";


export class CookiePersistedQueryStringValue extends CookiePersistedValue<string, string | Nil, string> {
  #queryName: string;
  constructor({ queryName, cookieName, cookieReadOptions = null, defaultValue = "" }: ({ queryName: string, cookieName: string, cookieReadOptions?: CookieReadOptions<string> | Nil, defaultValue?: string })) {
    super({ cookieName, cookieReadOptions, readCookieBeforeCalculating: false, defaultValue: defaultValue ?? "" });
    this.#queryName = queryName;
  }

  get QueryName() {
    return this.#queryName;
  }

  getValueFromQuery(): string | Nil {
    return getQueryParameter(this.#queryName);
  }

  override calculateValue(): string | Nil {
    return this.getValueFromQuery();
  }
}