import { ValueGetters } from "../tracking/data/ValueGetters.js";
import { exhaustiveGuard, isNil, type Nil } from "@mcwd/typescript-type-guards";

type InferredSiteLang = typeof ValueGetters.InferredSiteLang.value;
type CurrentLang = ReturnType <typeof window.AppState.GetCurrentLanguageName>;
const LANG_SUGGESTION_COOKIE_NAME = "mc-lang-suggest" as const;

/** 
 * `"dismissed"` - Signals that the user has dismissed the language popup
 * 
 * `"not-set"` - Signals that the browser allows cookies to be set, but the cookie does not have a value yet.
 * 
 * `null` - If the browser does not allow cookies to be set, the `readLangPopupCookie` function will return null after the `setDefaultCookieOnPageLoad` has ran during the initial load. We don't want the popup to show every single time if cookies are blocked.
 */
const POSSIBLE_COOKIE_VALUE = ["dismissed", "not-set"] as const;
type POSSIBLE_COOKIE_VALUE = (typeof POSSIBLE_COOKIE_VALUE)[number];

function readLangPopupCookie() {
    const value = window.AppState.getCookie(LANG_SUGGESTION_COOKIE_NAME);
    if (POSSIBLE_COOKIE_VALUE.includes(value as POSSIBLE_COOKIE_VALUE)) {
        return value as POSSIBLE_COOKIE_VALUE;
    }
    return null;
}

function writeLangPopupCookie(value: POSSIBLE_COOKIE_VALUE, expires: 1 | 30 = 30) {
    if (POSSIBLE_COOKIE_VALUE.includes(value)) {
        window.AppState.setCookie(LANG_SUGGESTION_COOKIE_NAME, value, { expires });
    }
}

/** Attempt to set the cookie if it does not already exist. If this fails, we can assume that cookies are blocked. */
function setDefaultCookieOnPageLoad() {
    const initialCookieValue = readLangPopupCookie();
    if (isNil(initialCookieValue)) {
        writeLangPopupCookie("not-set", 1);
    }
}

setDefaultCookieOnPageLoad();

function getMessage (userDetectedLang: Exclude<InferredSiteLang, Nil>) {
    switch (userDetectedLang) {
        case "en-AU": return "Our site is also available for Australia";
        case "en-GB": return "Our site is also available for the UK";
        case "en-US": return "Our site is also available for the US";
        case "fr-FR": return "Notre site est aussi disponible en français.";
        case "ja-JP": return "Our site is also available for Japan";
        case "zh-CN": return "Our site is also available for China";
        case "ko-KR": return null;
        default: exhaustiveGuard(userDetectedLang);
    }
}


function checkPopupNeeded (userDetectedLang: InferredSiteLang, currentLang:CurrentLang) {
    if (isNil(userDetectedLang)) {
        return false;
    }

    const isIgnoredLang = (userDetectedLang === "ko-KR");
    if (isIgnoredLang) {
        return false;
    }

    const detectedMatchesCurrent =
        (userDetectedLang === currentLang) ||
        (userDetectedLang === "en-US" && currentLang === "en");

    return !detectedMatchesCurrent;
}

function checkPopupBlocked() {
    const cookieVal = readLangPopupCookie();
    // If the popup has been dismissed or if cookies are blocked, don't show the popup
    return (cookieVal === "dismissed" || isNil(cookieVal));
}

function getLangPopupElements() {
    const langPopup = document.querySelector("#lang-popup");
    const popupText = langPopup?.querySelector(".js-lang-popup-text") ?? null;
    const closeBtn = langPopup?.querySelector(".close-button") ?? null;
    
    if (isNil(popupText) || isNil(langPopup) || isNil(closeBtn)) {
        console.debug("A language preference dialogue element was not found: ", { langPopup, popupText, closeBtn });
        return null;
    }
    return { langPopup, popupText, closeBtn };
}

function getDetectedAndCurrentLang() {
    const userDetectedLang = ValueGetters.InferredSiteLang.value;
    const currentLang = window.AppState.GetCurrentLanguageName();
    return { userDetectedLang, currentLang };
}

export function initializeLanguagePopup() {
    console.debug("Initializing Language Popup");

    if (checkPopupBlocked()) {
        console.debug("Language preference dialogue not activated");
        return;
    }

    const htmlElsObj = getLangPopupElements();
    if (isNil(htmlElsObj)) {
        return;
    }
    const { langPopup, popupText, closeBtn } = htmlElsObj;
    const { userDetectedLang, currentLang } = getDetectedAndCurrentLang();

    const showPopup = checkPopupNeeded(userDetectedLang,currentLang);
    if (showPopup === false || isNil(userDetectedLang)){
        return;
    }

    const message = getMessage(userDetectedLang);
    if (isNil(message)) {
        return;
    } 

    popupText.textContent = message;
    langPopup.classList.add("active");
    closeBtn.addEventListener("click", () => {
        langPopup.classList.remove("active");
        writeLangPopupCookie("dismissed");
    });

    /* 
        <div id="lang-popup">
            <span class="js-lang-popup-text"></span>
            <div aria-label="Close" class="close-button">
                <em class="fas fa-times"></em>
            </div >
        </div> 
    */
}