import { isFunction, isString, type Nil } from "@mcwd/typescript-type-guards";

export type QueryParamParseFn<T> = (val: string) => (T | undefined);
export function getQueryParameter(key: string): string | Nil;
export function getQueryParameter<T>(key: string, { defaultValue }: { defaultValue: T }): string | T | Nil;
export function getQueryParameter<T>(key: string, { parse }: { parse: QueryParamParseFn<T> }): T | Nil;
export function getQueryParameter<T>(key: string, { defaultValue, parse }: { defaultValue: T, parse: QueryParamParseFn<T> }): T | string | Nil;

export function getQueryParameter<T = string>(
  key: string,
  { defaultValue, parse }: { defaultValue?: T | Nil; parse?: QueryParamParseFn<T> } = {}
): T | string | Nil
{
  const searchParams = new URLSearchParams(window.location.search);
  const value = searchParams.get(key)?.trim();

  let returnVal: T | string | Nil;
  if (isFunction(parse) && isString(value)) {
    try {
      returnVal = parse(value);
    } catch (e) {
      console.warn(e);
    }
  }
  else {
    returnVal = value as T;
  }

  return (returnVal ?? defaultValue);
}