import { isArray } from "./array-checks.js";
import { isNil, isString } from "./primitive-types.js";

export function convertCamelToKebabCase(str) {
  return str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, (x, sep) => (sep ? "-" : "") + x.toLowerCase());
}

export function convertKebabToCamelCase(str) {
  return str.replace(/-./g, (x) => x[1].toUpperCase());
}

export function convertToPascalCase(str) {
  return str
    .replace(/[ _-]([^ _-])/g, x => x[1].toUpperCase()) // Capitalize char after space, -, or _
    .replace(/[^.]/, x => x[0].toUpperCase()); // Capitalize first char
}

export function isEmptyString(value) {
  return value === "";
}

export function isNilOrEmptyString(value) {
  return isNil(value) || isEmptyString(value);
}

export function isStringLiteralOfType(value, possibleValues) {
  return isArray(possibleValues) && isString(value) && possibleValues.includes(value);
}