import { mapArrayToProp } from "@mcwd/typescript-type-guards";
import { LocaleSettingsArray } from "../locale-settings.js";

/**
 * The 2 letter country codes used on our site.
 */
export const SiteCountryCode = mapArrayToProp(LocaleSettingsArray, "SiteCountryCode");
export type SiteCountryCode = typeof SiteCountryCode[number];

/**
 * The 2 letter country codes used on our site.
 */
export const SiteCountryCodeUppercase = mapArrayToProp(LocaleSettingsArray, "SiteCountryCodeUppercase");
export type SiteCountryCodeUppercase = typeof SiteCountryCodeUppercase[number];

/**
 * The 2 letter country codes used on our site.
 */
export const SiteCountryCodeAnyCase = [...SiteCountryCode, ...SiteCountryCodeUppercase] as const;
export type SiteCountryCodeAnyCase = typeof SiteCountryCodeAnyCase[number];

export function isSiteCountryCode(value: unknown): value is SiteCountryCode {
  return (SiteCountryCode as readonly string[]).includes((value as string));
}

export function isSiteCountryCodeUppercase(value: unknown): value is SiteCountryCodeUppercase {
  return (SiteCountryCodeUppercase as readonly string[]).includes((value as string));
}