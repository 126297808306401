import { mapArrayToProp } from "@mcwd/typescript-type-guards";
import { LocaleSettingsArray } from "../locale-settings.js";

/** 
 * A 2 letter language code `ISO 639-2` seperated by a `-` and followed by an uppercase 2 letter country code `ISO 3166`
 * 
 * Example: `en-US`: 
 * - `en` 2 letter language code 
 * - `US` 2 lettter country code 
 */
export const LanguageCountryLocale = mapArrayToProp(LocaleSettingsArray, "LanguageCountryLocale");
export type LanguageCountryLocale = typeof LanguageCountryLocale[number];

export const LanguageCountryLocaleLowercase = mapArrayToProp(LocaleSettingsArray, "LanguageCountryLocaleLowercase");
export type LanguageCountryLocaleLowercase = typeof LanguageCountryLocaleLowercase[number];

export const LanguageCountryLocaleUppercase = mapArrayToProp(LocaleSettingsArray, "LanguageCountryLocaleUppercase");
export type LanguageCountryLocaleUppercase = typeof LanguageCountryLocaleUppercase[number];

export const LanguageCountryLocaleAnyCase = [...LanguageCountryLocale, ...LanguageCountryLocaleLowercase, ...LanguageCountryLocaleUppercase] as const;
export type LanguageCountryLocaleAnyCase = typeof LanguageCountryLocaleAnyCase[number];