export const WindowConsole = window.console;
export type WindowConsole = typeof WindowConsole;

export type WindowConsoleFunctionKeys = {
    [K in keyof WindowConsole]: (WindowConsole[K] extends (...args: any[]) => any ? K : never);
}[keyof WindowConsole];

export const ConsoleLoggingLevels = ["debug", "info", "warn", "error"] as const;
export type ConsoleLoggingLevels = typeof ConsoleLoggingLevels[number];

export const BasicConsoleFnKeys = ["debug", "dirxml", "error", "group", "groupCollapsed", "info", "log", "trace", "warn"] as const;
export type BasicConsoleFnKeys = typeof BasicConsoleFnKeys[number];