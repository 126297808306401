import { createReverseObjectMap, type ReverseObjectMap } from "@mcwd/typescript-type-guards";

const onetrust_category_id_to_name_mapping = {
  "C0001": "Strictly Necessary",
  "C0002": "Performance",
  "C0003": "Functional",
  "C0004": "Targeting",
  "C0005": "Social Media"
} as const;

type onetrust_category_id_to_name_mapping = typeof onetrust_category_id_to_name_mapping;
type onetrust_category_name_to_id_mapping = ReverseObjectMap<onetrust_category_id_to_name_mapping>;

const onetrust_category_name_to_id_mapping = createReverseObjectMap(onetrust_category_id_to_name_mapping) as onetrust_category_name_to_id_mapping;

type onetrust_category_id = keyof onetrust_category_id_to_name_mapping;
type onetrust_category_name = onetrust_category_id_to_name_mapping[onetrust_category_id];


const onetrust_category_id = Object.keys(onetrust_category_id_to_name_mapping) as onetrust_category_id[];
const onetrust_category_name = Object.values(onetrust_category_id_to_name_mapping) as onetrust_category_name[];



Object.freeze(onetrust_category_id_to_name_mapping);
Object.freeze(onetrust_category_name_to_id_mapping);
Object.freeze(onetrust_category_id);
Object.freeze(onetrust_category_name);


export type OneTrustCategoryIdToNameMapping = typeof onetrust_category_id_to_name_mapping;
export const OneTrustCategoryIdToNameMapping = onetrust_category_id_to_name_mapping;

export type OneTrustCategoryNameToIdMapping = typeof onetrust_category_name_to_id_mapping;
export const OneTrustCategoryNameToIdMapping = onetrust_category_name_to_id_mapping;

export type OneTrustCategoryId = keyof OneTrustCategoryIdToNameMapping;
export const OneTrustCategoryId = onetrust_category_id;

export type OneTrustCategoryName = OneTrustCategoryIdToNameMapping[OneTrustCategoryId]; 
export const OneTrustCategoryName = onetrust_category_name as OneTrustCategoryName[];

export type OneTrustCategoryLabels = `${OneTrustCategoryName} Cookies`;
export const OneTrustCategoryLabels = Object.freeze(OneTrustCategoryName.map(n => `${n} Cookies`)) as OneTrustCategoryLabels[];