


export function getValueFromPropsPath(item, path, valueIfPathInvalid) {
  let curValue = item;
  for (const prop of path) {
    if (prop in curValue) {
      curValue = item[prop];
    }
    else {
      return valueIfPathInvalid;
    }
  }
  return curValue;
}