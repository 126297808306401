import type { ILogger } from "./ILogger.js";
import { getStyledMessageArgs } from "./LoggerStyles.js";
import { WindowConsole, type ConsoleLoggingLevels, type WindowConsoleFunctionKeys } from "./WindowConsole.js";

export function getLoggerPrefixArgs(logger: ILogger, level: ConsoleLoggingLevels): string[] {
    return getStyledMessageArgs({ level, messages: [... logger.prefixes], styles: [... logger.prefixStyles] });
}

export function getConsoleFn<TFnName extends WindowConsoleFunctionKeys>(fnName: TFnName): WindowConsole[TFnName] {
    if (fnName in WindowConsole) {
        return WindowConsole[fnName];
    }
    else {
        throw new Error(`${fnName} is NOT a function`);
    }
}

export function getBoundConsoleFn<TFnName extends WindowConsoleFunctionKeys>(fnName: TFnName, ...args: any[]): WindowConsole[TFnName] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return getConsoleFn(fnName).bind(WindowConsole, ...args);
}