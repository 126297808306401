import { mapArrayToProp } from "@mcwd/typescript-type-guards";
import { LocaleSettingsArray } from "../locale-settings.js";

/**
 * A 2 letter country code `ISO 3166`
 */
export const CountryLocale = mapArrayToProp(LocaleSettingsArray, "CountryLocale");
export type CountryLocale = typeof CountryLocale[number];


/**
 * A 2 letter country code `ISO 3166`
 */
export const CountryLocaleUpperCase = mapArrayToProp(LocaleSettingsArray, "CountryLocaleUppercase");
export type CountryLocaleUpperCase = typeof CountryLocaleUpperCase[number];


/**
 * A 2 letter country code `ISO 3166`
 */
export const CountryLocaleAnyCase = [...CountryLocale, ...CountryLocaleUpperCase] as const;
export type CountryLocaleAnyCase = typeof CountryLocaleAnyCase[number];