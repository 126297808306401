import type { ILanguageLocale } from "./ILanguageLocale.js";
  
export function createLanguageLocale<
  const TLanguageName extends string,
  const TLanguageLocale extends string
>({ languageName, languageLocale }: { languageName: TLanguageName, languageLocale: TLanguageLocale }) {
  const locale = {
    LanguageName: languageName,
    LanguageLocale: languageLocale,
    LanguageLocaleUppercase: languageLocale.toUpperCase() as Uppercase<TLanguageLocale>,
  } as const satisfies ILanguageLocale<TLanguageName, TLanguageLocale>;
  Object.freeze(locale);

  return locale;
}