import type { LanguageCountryLocale, LanguageCountryLocaleAnyCase } from "./LanguageCountryLocale.js";
import { type SiteCountryCodeAnyCase } from "./SiteCountryCode.js";
import { GetSiteLocale } from "./GetSiteLocaleBy.js";
import type { ISiteLocale } from "../create/ISiteLocale.js";
import type { WithDefault } from "@mcwd/typescript-type-guards";

type DefaultFromThrowIfMissing<TThrowIfMissing extends boolean | undefined>
  = WithDefault<TThrowIfMissing, false> extends true
  ? never
  : null;

export namespace LocaleConvert {
  export namespace FromSiteCountryCode {
    type GetLocaleProp<
      TSiteCountryCode extends SiteCountryCodeAnyCase,
      TProp extends keyof ISiteLocale,
      TDefault = never
    > = GetSiteLocale.bySiteCountryCode<TSiteCountryCode>[TProp] | TDefault;
    
    export type ToUrlPrefix<
      TSiteCountryCode extends SiteCountryCodeAnyCase,
      TDefault = never
    > = GetLocaleProp<TSiteCountryCode, "UrlPrefix", TDefault>;

    export function ToUrlPrefix<
      TSiteCountryCode extends SiteCountryCodeAnyCase,
      const TThrowIfMissing extends boolean | undefined
    >(
      siteCountryCode: TSiteCountryCode,
      throwIfMissing?: TThrowIfMissing
    ): ToUrlPrefix<TSiteCountryCode, DefaultFromThrowIfMissing<TThrowIfMissing>> {
      type ThisReturnType = ToUrlPrefix<TSiteCountryCode, DefaultFromThrowIfMissing<TThrowIfMissing>>;
      
      return GetSiteLocale.bySiteCountryCode(siteCountryCode, throwIfMissing)?.UrlPrefix as ThisReturnType;
    }

    export type ToLanguageCountryLocale<
      TSiteCountryCode extends SiteCountryCodeAnyCase,
      TDefault = never
    > = GetLocaleProp<TSiteCountryCode, "LanguageCountryLocale", TDefault>;

    export function ToLanguageCountryLocale<
      TSiteCountryCode extends SiteCountryCodeAnyCase,
      const TThrowIfMissing extends boolean | undefined
    >(
      siteCountryCode: TSiteCountryCode,
      throwIfMissing?: TThrowIfMissing
    ): ToLanguageCountryLocale<TSiteCountryCode, DefaultFromThrowIfMissing<TThrowIfMissing>> {
      type ThisReturnType = ToLanguageCountryLocale<TSiteCountryCode, DefaultFromThrowIfMissing<TThrowIfMissing>>;
      return GetSiteLocale.bySiteCountryCode(siteCountryCode, throwIfMissing)?.LanguageCountryLocale as ThisReturnType;
    }
  }

  export namespace FromLanguageCountry {
    type GetLocaleProp<
      TLocale extends LanguageCountryLocaleAnyCase,
      TProp extends keyof ISiteLocale,
      TDefault = never
    > = GetSiteLocale.byLanguageCountryLocale<TLocale>[TProp] | TDefault;

    export type ToUrlPrefix<
      TLocale extends LanguageCountryLocaleAnyCase,
      TDefault = never
    > = GetLocaleProp<TLocale, "UrlPrefix", TDefault>;

    export function ToUrlPrefix<
      TLocale extends LanguageCountryLocale,
      const TThrowIfMissing extends boolean | undefined
    >(
      locale: TLocale,
      throwIfMissing?: TThrowIfMissing
    ): ToUrlPrefix<TLocale, DefaultFromThrowIfMissing<TThrowIfMissing>> {
      type ThisReturnType = ToUrlPrefix<TLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>;
      return GetSiteLocale.byLanguageCountryLocale(locale, throwIfMissing)?.UrlPrefix as ThisReturnType;
    }

    export type ToCountryLocale<
      TLocale extends LanguageCountryLocaleAnyCase,
      TDefault = never
    > = GetLocaleProp<TLocale, "CountryLocale", TDefault>;

    export function ToCountryLocale<
      TLocale extends LanguageCountryLocale,
      TThrowIfMissing extends boolean | undefined
    >(
      locale: TLocale,
      throwIfMissing?: TThrowIfMissing
    ): ToCountryLocale<TLocale, DefaultFromThrowIfMissing<TThrowIfMissing>> {
      type ThisReturnType = ToCountryLocale<TLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>;
      return GetSiteLocale.byLanguageCountryLocale(locale, throwIfMissing)?.CountryLocale as ThisReturnType;
    }
  }

}
