import type { Nil } from "@mcwd/typescript-type-guards";

export interface OneTrustGroupData {
  id: string;
  name?: string | Nil;
  enabled?: boolean | Nil;
}
export interface OptanonConsentCookieObject {
  isGpcEnabled?: boolean | Nil;
  datestamp?: Date | Nil;
  version?: string | Nil;
  browserGpcFlag?: boolean | Nil;
  isIABGlobal?: boolean | Nil;
  hosts?: string | Nil;
  consentId?: string | Nil;
  interactionCount?: number | Nil;
  landingPath?: string | Nil;
  GPPCookiesCount?: number | Nil;
  groups?: OneTrustGroupData[] | Nil;
  AwaitingReconsent?: boolean | Nil; 
  geolocation?: { countryCode?: string | Nil, stateCode?: string | Nil } | Nil;
}

export const OptanonConsentCookieProp = [
  'isGpcEnabled', 'datestamp', 'version', 'browserGpcFlag', 'isIABGlobal', 'hosts', 'consentId', 'interactionCount', 'landingPath', 'GPPCookiesCount', 'groups', 'AwaitingReconsent', 'geolocation'
] as const satisfies readonly (keyof OptanonConsentCookieObject)[]; 

export type OptanonConsentCookieProp = typeof OptanonConsentCookieProp[number];