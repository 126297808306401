import { mapArrayToProp, uniqueTupleValues } from "@mcwd/typescript-type-guards";
import { LocaleSettingsArray } from "../locale-settings.js";

/** 2 letter code representing a language name according to `ISO 639-2` */
export const LanguageLocale = uniqueTupleValues(mapArrayToProp(LocaleSettingsArray, "LanguageLocale"));
export type LanguageLocale = typeof LanguageLocale[number];

/** 2 letter code representing a language name according to `ISO 639-2` */
export const LanguageLocaleUppercase = uniqueTupleValues(mapArrayToProp(LocaleSettingsArray, "LanguageLocaleUppercase"));
export type LanguageLocaleUppercase = typeof LanguageLocaleUppercase[number];

/** 2 letter code representing a language name according to `ISO 639-2` */
export const LanguageLocaleAnyCase = [...LanguageLocale, ...LanguageLocaleUppercase] as const;
export type LanguageLocaleAnyCase = typeof LanguageLocaleAnyCase[number];