import { type CookieReadOptions, type CookieValueType } from "../../../utils/cookieUtils.js";
import { type Nil } from "@mcwd/typescript-type-guards";

import { CookiePersistedValue } from "./CookiePersistedValue.js";

export class GenericCookiePersistedValue<T extends CookieValueType, TCalculate extends T | (T | Nil) = (T | Nil), TDefault extends T | undefined = T | undefined> extends CookiePersistedValue<T, TCalculate, TDefault> {
  #valueGetter: () => TCalculate;
  constructor({ cookieName, cookieReadOptions = null, valueGetter, readCookieBeforeCalculating, defaultValue }: ({ cookieName: string, cookieReadOptions?: CookieReadOptions<T> | Nil, valueGetter: () => TCalculate, readCookieBeforeCalculating: boolean, defaultValue: TDefault })) {
    super({ cookieName, cookieReadOptions, readCookieBeforeCalculating, defaultValue });
    this.#valueGetter = valueGetter;
  }

  getValueFromGetterFn(): TCalculate {
    return this.#valueGetter();
  }

  override calculateValue(): TCalculate {
    return this.getValueFromGetterFn();
  }
}