import type { ILanguageCountryLocale } from "./ILanguageCountryLocale.js";
import { createCountryLocale } from "./createCountryLocale.js";
import { createLanguageLocale } from "./createLanguageLocale.js";

export function createLanguageCountryLocale<
  const TLanguageName extends string,
  const TCountryName extends string,
  const TLanguageLocale extends string,
  const TCountryLocale extends string
>({
  languageName,
  countryName,
  languageLocale,
  countryLocale
}: {
  languageName: TLanguageName,
  countryName: TCountryName,
  languageLocale: TLanguageLocale,
  countryLocale: TCountryLocale
}) {
  const languageCountryLocale = `${languageLocale}-${countryLocale.toUpperCase() as Uppercase<TCountryLocale>}` as const;
  const locale = {
    ...createLanguageLocale({ languageName, languageLocale }),
    ...createCountryLocale({ countryName, countryLocale }),
    LanguageCountryLocale: languageCountryLocale,
    LanguageCountryLocaleLowercase: (languageCountryLocale as string).toLowerCase() as `${Lowercase<TLanguageLocale>}-${Lowercase<TCountryLocale>}`,
    LanguageCountryLocaleUppercase: (languageCountryLocale as string).toUpperCase() as `${Uppercase<TLanguageLocale>}-${Uppercase<TCountryLocale>}`,
  } as const satisfies ILanguageCountryLocale<TLanguageName, TLanguageLocale, TCountryName, TCountryLocale>;

  Object.freeze(locale);

  return locale;
}