import { getCookie, removeCookie } from "../../utils/cookieUtils.js";
import { isArray, isNil, isNotNil, isObject, isString, type Nil, type PartialWithNil } from '@mcwd/typescript-type-guards';
import { OptanonConsentCookieProp, type OneTrustGroupData, type OptanonConsentCookieObject } from "./OptanonContentCookieTypes.js";
import { parseBool, parseNumber } from "@mcwd/util-parse-and-convert";
import { OneTrustCategoryId, OneTrustCategoryIdToNameMapping, OneTrustCategoryName } from "./OneTrustCategoryIdToNameMapping.js";
import { onetrustLogger as logger, onetrustVerboseLogger as verboseLogger } from "./oneTrustLogger.js";

(window as any).getCookie = getCookie;
(window as any).removeCookie = removeCookie;

function parseCookieAsObject(cookieStr: string | Nil) {
  if (isNil(cookieStr) || cookieStr?.trim().length === 0) {
    return null;
  }
  const entries = cookieStr.split("&").map(kv => kv.split('=')) as ([string, string])[];
  return (entries.length > 0) ? Object.fromEntries(entries) as Record<string, string> : null;
}

function parseOneTrustGroups(groupsStr: string | Nil, suppressMissingCategoryError = false): OneTrustGroupData[] | Nil {
  if (isNil(groupsStr)) {
    return null;
  }
  const splitGroups: [string, string][] = groupsStr.split(",").map(c => c.split(":") as [string, string]);
  const groupsObj = splitGroups.map(([id, enabled]) => {
    if (!(suppressMissingCategoryError) && !(OneTrustCategoryId as readonly string[]).includes(id)) {
      logger.error(`Unknown OneTrust Category '${id}'`);
    }
    const name = (id in OneTrustCategoryIdToNameMapping) ? OneTrustCategoryIdToNameMapping[id] as OneTrustCategoryName : null;
    return { id, name, enabled: parseBool(enabled, { parseFromNumber: true }) }; 
  });
  return groupsObj;
}

function parseGeoLocation(geoLocationString: string | Nil): { countryCode?: string | Nil, stateCode?: string | Nil } | Nil {
  if (!isString(geoLocationString)) {
    return null;
  }
  const geo = geoLocationString.replace(/;$/, "").split(";").map(s => s.trim());
  if (geo.length === 0 || (geo.length === 1 && geo[0] === "") || (geo.length === 2 && geo[0] === "" && geo[1] === "")) {
    return null;
  }
  else {
    return { countryCode: geo[0], stateCode: geo[1] };
  }
}

function parseOptanonCookieValue<TKey extends OptanonConsentCookieProp>(
  key: TKey,
  cookieObj: Record<OptanonConsentCookieProp, string>
): OptanonConsentCookieObject[TKey] | Nil {
  type ThisReturnType = OptanonConsentCookieObject[TKey] | Nil;
  const curStrVal: string = cookieObj[key];
  if (!OptanonConsentCookieProp.includes(key)) {
    return curStrVal as OptanonConsentCookieObject[TKey] | Nil;
  }
  if (isString(curStrVal) && curStrVal.trim().length !== 0) {
    switch (key) {
      case "isGpcEnabled": return parseBool(curStrVal) as ThisReturnType;
      case "datestamp": return new Date(curStrVal.replace(/\+/g, " ")) as ThisReturnType;
      case "version": return curStrVal as ThisReturnType;
      case "browserGpcFlag": return parseNumber(curStrVal) as ThisReturnType;
      case "isIABGlobal": return parseBool(curStrVal) as ThisReturnType;
      case "consentId": return curStrVal as ThisReturnType;
      case "interactionCount": return parseNumber(curStrVal) as ThisReturnType;
      case "landingPath": return curStrVal as ThisReturnType;
      case "GPPCookiesCount": return parseNumber(curStrVal) as ThisReturnType;
      case "groups": return parseOneTrustGroups(curStrVal) as ThisReturnType;
      case "AwaitingReconsent": return parseBool(curStrVal) as ThisReturnType;
      case "geolocation": return parseGeoLocation(curStrVal) as ThisReturnType;
      case "hosts": return curStrVal as ThisReturnType;
      default:
        logger.warn(`Unexpected property '${key as string}' in OptanonConsent`);
        return curStrVal as ThisReturnType;
    }
  }
  return null;
}
function parseOptanonConsentCookieObject(simpleCookieObj: Record<string, string> | Nil) {
  try {
    if (isNil(simpleCookieObj)) {
      return null;
    }
    const oneTrustObj = {} as PartialWithNil<OptanonConsentCookieObject>;
    for (const k of Object.keys(simpleCookieObj) as OptanonConsentCookieProp[]) {
      const curVal = parseOptanonCookieValue(k, simpleCookieObj);
      if (isNotNil(curVal)) {
        (oneTrustObj as any)[k] = curVal;
      }
    }
    return (Object.keys(oneTrustObj).length > 0 ? oneTrustObj : null) as OptanonConsentCookieObject | Nil;
  }
  catch (err) {
    logger.error(err);
    return null;
  }
}

async function getHashFromStr(value: string | Nil) {
  try {
    if (isNil(value)) {
      return value;
    }
    const uftEncodedVal = new TextEncoder().encode(value);
    const hashBuffer = await crypto.subtle.digest("SHA-256", uftEncodedVal);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashAsHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashAsHex;
  }
  catch (err) {
    logger.error(err);
    return null;
  }
}

const PreviousOptanonCookieVal = {
  asString: null as string | Nil,
  asObject: null as OptanonConsentCookieObject | Nil,
  asHash: null as string | Nil
};

function removeOldVersionCookies() {
  for (const cookieName of ["OptanonConsent", "OptanonAlertBoxClosed"]) {
    removeCookie(cookieName);
    const rootCookieOpts = { domain: ".mastercontrol.com", path: "/", sameSite: "Lax" as const};
    const otherCookieOpts = {
      path: "/",
      sameSite: "Lax" as const,
      ...(/^[a-z_-]+.mastercontrol.com/.test(window.location.host) ? { domain: window.location.host } : {})
    };
    for (const cookieOpts of [rootCookieOpts, otherCookieOpts]) {
      if (isNotNil(getCookie(cookieName))) {
        removeCookie(cookieName, cookieOpts);
      }
    }
    if (isNotNil(getCookie(cookieName))) {
      logger.error(`Could not fully remove old ${cookieName} cookie`);
    }
  }
}

function checkCookieInvalid(simpleCookieObj: Record<string, string>): boolean {
  if (isString(simpleCookieObj.version) && simpleCookieObj.version.trim() === "6.7.0") {
    return true;
  }
  else {
    const groups = parseOneTrustGroups(simpleCookieObj.groups, true);
    const hasNoKnownGroups = isArray(groups) && groups.length > 0 && groups.filter(g => isNil(g.name)).length === groups.length;
    return hasNoKnownGroups;
  }
}

async function updatePreviousOptanonCookieVal({ asString, asHash, asObject }: { asString: string | Nil, asHash?: string | Nil, asObject: OptanonConsentCookieObject | Nil }) {
  PreviousOptanonCookieVal.asString = asString;
  PreviousOptanonCookieVal.asObject = asObject;
  if (isNil(asHash) && isString(asString) && asString !== "") {
    asHash = await getHashFromStr(asString);
  }
  PreviousOptanonCookieVal.asHash = asHash;
}

export async function readOneTrustCookie() {
  let oneTrustCookieStr = getCookie("OptanonConsent")?.trim() ?? null;
  let newHash = await getHashFromStr(oneTrustCookieStr);
  if (isNotNil(PreviousOptanonCookieVal.asHash) && PreviousOptanonCookieVal.asHash === newHash && isNotNil(PreviousOptanonCookieVal.asString) && isNotNil(PreviousOptanonCookieVal.asObject)) {
    return PreviousOptanonCookieVal.asObject;
  }
  let simpleCookieObj = parseCookieAsObject(oneTrustCookieStr);
  if (isObject(simpleCookieObj) && checkCookieInvalid(simpleCookieObj) === true) {
    removeOldVersionCookies();
    oneTrustCookieStr = null;
    simpleCookieObj = null;
    newHash = null;
  }
  const optanonConsentCookieObject = parseOptanonConsentCookieObject(simpleCookieObj);
  await updatePreviousOptanonCookieVal({ asString: oneTrustCookieStr, asObject: optanonConsentCookieObject, asHash: newHash });
  verboseLogger.debug("OptanonConsent value: ", optanonConsentCookieObject);
  return optanonConsentCookieObject;
}

export function readOneTrustCookieSync() {
  const oneTrustCookieStr = getCookie("OptanonConsent")?.trim() ?? null;
  if (isNotNil(PreviousOptanonCookieVal.asString)
    && isNotNil(PreviousOptanonCookieVal.asObject)
    && oneTrustCookieStr === PreviousOptanonCookieVal.asString) {
    return PreviousOptanonCookieVal.asObject;
  }
  const simpleCookieObj = parseCookieAsObject(oneTrustCookieStr);
  if (isObject(simpleCookieObj) && checkCookieInvalid(simpleCookieObj) === true) {
    removeOldVersionCookies();
    updatePreviousOptanonCookieVal({
      asString: null,
      asObject: null,
      asHash: null
    }).catch(e => logger.error(e));
    return null;
  }
  const optanonConsentCookieObject = parseOptanonConsentCookieObject(simpleCookieObj);
  getHashFromStr(oneTrustCookieStr).then(async newHash => {
    return updatePreviousOptanonCookieVal({ asString: oneTrustCookieStr, asObject: optanonConsentCookieObject, asHash: newHash });
  }).catch(err => logger.error(err));
  verboseLogger.debug("OptanonConsent value: ", optanonConsentCookieObject);
  return optanonConsentCookieObject;
}