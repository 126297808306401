import { exhaustiveGuard, type ConvertArrayToDeepPropIndexedObject, type ConvertArrayToPropIndexedObject, type TryGetValue, type WithDefault } from "@mcwd/typescript-type-guards";
import { LocaleSettingsArray, SiteLocaleSettings, SiteLocaleWithExtendedCountryLocaleArray } from "../locale-settings.js";
import type { LanguageCountryLocaleAnyCase } from "./LanguageCountryLocale.js";
import { isSiteCountryCode, type SiteCountryCodeAnyCase } from "./SiteCountryCode.js";
import type { CountryLocale, CountryLocaleAnyCase } from "./CountryLocale.js";
import type { ILanguageCountryLocale } from "../create/ILanguageCountryLocale.js";
import type { ICountryLocale } from "../create/ICountryLocale.js";
import type { ILocaleArray } from "../create/ILocaleArray.js";
import { ExtendedLocaleSettingsObject, type ExtendedLocaleCountry } from "./ExtendedLocale.js";
import type { ISiteLocaleWithExtendedCountryLocales } from "../create/ISiteLocale.js";


type DefaultFromThrowIfMissing<TThrowIfMissing extends boolean | undefined>
  = WithDefault<TThrowIfMissing, false> extends true
  ? never
  : null;

type SettingsIndexedByCountryLocale<TLocaleArray extends ILocaleArray<ICountryLocale>> = ConvertArrayToPropIndexedObject<TLocaleArray, "CountryLocale">;
type SettingsIndexedByLanguageCountryLocale<TLocaleArray extends ILocaleArray<ILanguageCountryLocale>> = ConvertArrayToPropIndexedObject<TLocaleArray, "LanguageCountryLocaleLowercase">;
type SettingsIndexedByExtendedLocale = ConvertArrayToDeepPropIndexedObject<SiteLocaleWithExtendedCountryLocaleArray, ["ExtendedCountryLocales", number, "CountryLocale"]>;

export namespace GetLocale {

  export type byCountryLocale<
    TLocaleArray extends ILocaleArray<ICountryLocale>,
    TCountryLocale extends TLocaleArray[number]["CountryLocale"] | TLocaleArray[number]["CountryLocaleUppercase"],
    TDefault = never
  > = TryGetValue<SettingsIndexedByCountryLocale<TLocaleArray>, Lowercase<TCountryLocale>, TDefault>;

  export function byCountryLocale<
    TLocaleArray extends ILocaleArray<ICountryLocale>,
    TCountryLocale extends TLocaleArray[number]["CountryLocale"] | TLocaleArray[number]["CountryLocaleUppercase"],
    const TThrowIfMissing extends boolean | undefined
  >(
    localeArray: TLocaleArray,
    countryLocale: TCountryLocale,
    throwIfMissing?: TThrowIfMissing
  ): byCountryLocale<TLocaleArray, TCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>
  {
    type ThisReturnType = byCountryLocale<TLocaleArray, TCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>
    const countryLocaleLower = countryLocale.toLowerCase() as CountryLocale;
    const res = localeArray.find(el => el.CountryLocale === countryLocaleLower);
    if (res) {
      return res as ThisReturnType;
    }
    else if (throwIfMissing){
      throw new Error(`ERROR! Unexpected value for parameter 'countryLocale': ${countryLocale}`);
    }
    return null as ThisReturnType;
  }

  export type byLanguageCountryLocale<
    TLocaleArray extends ILocaleArray<ILanguageCountryLocale>,
    TLanguageCountryLocale extends TLocaleArray[number]["LanguageCountryLocale"] | TLocaleArray[number]["LanguageCountryLocaleUppercase"] | TLocaleArray[number]["LanguageCountryLocaleLowercase"],
    TDefault = never
  > = TryGetValue<SettingsIndexedByLanguageCountryLocale<TLocaleArray>, Lowercase<TLanguageCountryLocale>, TDefault>;

  export function byLanguageCountryLocale<
    TLocaleArray extends ILocaleArray<ILanguageCountryLocale>,
    TLanguageCountryLocale extends TLocaleArray[number]["LanguageCountryLocale"] | TLocaleArray[number]["LanguageCountryLocaleUppercase"] | TLocaleArray[number]["LanguageCountryLocaleLowercase"],
    const TThrowIfMissing extends boolean | undefined
  >(
    localeArray: TLocaleArray,
    languageCountryLocale: TLanguageCountryLocale,
    throwIfMissing?: TThrowIfMissing
  ): byLanguageCountryLocale<TLocaleArray, TLanguageCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>> {
    type ThisReturnType = byLanguageCountryLocale<TLocaleArray, TLanguageCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>;
    const langCountryLocaleLower = languageCountryLocale.toLowerCase() as Lowercase<TLanguageCountryLocale>;
    const res = localeArray.find(el => el.LanguageCountryLocaleLowercase === langCountryLocaleLower);
    if (res) {
      res as ThisReturnType;
    }
    else if (throwIfMissing) {
      throw new Error(`ERROR! Unexpected value for parameter 'languageCountryLocale': ${languageCountryLocale}`);
    }
    return null as ThisReturnType;
  }
}

export namespace GetExtendedLocale {
  export type byCountryLocale<TCountryLocale extends ExtendedLocaleCountry, TDefault = never>
    = ExtendedLocaleSettingsObject[Lowercase<TCountryLocale>] | TDefault;

  export function byCountryLocale<
    TExtendedCountryLocale extends ExtendedLocaleCountry | Uppercase<ExtendedLocaleCountry>,
    const TThrowIfMissing extends boolean | undefined
  >(
    extendedCountryLocale: TExtendedCountryLocale,
    throwIfMissing?: TThrowIfMissing
  ): byCountryLocale<Lowercase<TExtendedCountryLocale>, DefaultFromThrowIfMissing<TThrowIfMissing>> {
    type ThisReturnType = byCountryLocale<Lowercase<TExtendedCountryLocale>, DefaultFromThrowIfMissing<TThrowIfMissing>>
    const countryLocaleLower = extendedCountryLocale.toLowerCase() as ExtendedLocaleCountry;
    const res = ExtendedLocaleSettingsObject[countryLocaleLower];
    if (res) {
      return res as ThisReturnType;
    }
    else if (throwIfMissing) {
      throw new Error(`ERROR! Unexpected value for parameter 'countryLocale': ${extendedCountryLocale}`);
    }
    return null as ThisReturnType;
  }
}

export namespace GetSiteLocale {

  export type bySiteCountryCode<
    TSiteCountryCode extends SiteCountryCodeAnyCase,
    TDefault = never
  > = SiteLocaleSettings[Lowercase<TSiteCountryCode>] | TDefault;

  export function bySiteCountryCode<
    const TSiteCountryCode extends SiteCountryCodeAnyCase,
    const TThrowIfMissing extends boolean | undefined

  >(
    siteCountryCode: TSiteCountryCode,
    throwIfMissing?: TThrowIfMissing
  ): bySiteCountryCode<TSiteCountryCode, DefaultFromThrowIfMissing<TThrowIfMissing>> {
    type ThisReturnType = bySiteCountryCode<TSiteCountryCode, DefaultFromThrowIfMissing<TThrowIfMissing>>;
    const lowerCaseSiteCountryCode = siteCountryCode.toLowerCase() as Lowercase<TSiteCountryCode>;
    if (isSiteCountryCode(lowerCaseSiteCountryCode)) {
      const result = SiteLocaleSettings[lowerCaseSiteCountryCode];
      if (result) {
        return result;
      }
    }
    else if (throwIfMissing) {
      // Needs to be in an else statement in order to use exhaustiveGuard
      exhaustiveGuard(lowerCaseSiteCountryCode, "ERROR! Unexpected value for parameter 'siteCountryCode'");
    }
    if (throwIfMissing) {
      // Runtime error
      throw new Error(`ERROR! Unexpected value for parameter 'siteCountryCode': "${lowerCaseSiteCountryCode}"`);
    }
    return null as ThisReturnType;
  }


  export type byLanguageCountryLocale<
    TLanguageCountryLocale extends LanguageCountryLocaleAnyCase,
    TDefault = never
  > = SettingsIndexedByLanguageCountryLocale<LocaleSettingsArray>[Lowercase<TLanguageCountryLocale>] | TDefault;

  export function byLanguageCountryLocale<
    TLanguageCountryLocale extends LanguageCountryLocaleAnyCase,
    const TThrowIfMissing extends boolean | undefined
  >(
    languageCountryLocale: TLanguageCountryLocale,
    throwIfMissing?: TThrowIfMissing
  ): byLanguageCountryLocale<TLanguageCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>> {
    type ThisReturnType = byLanguageCountryLocale<TLanguageCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>;
    const result = GetLocale.byLanguageCountryLocale(LocaleSettingsArray, languageCountryLocale);
    if (result) {
      return result as ThisReturnType;
    }
    if (throwIfMissing) {
      throw new Error(`ERROR! Unexpected value for parameter 'languageCountryLocale': "${languageCountryLocale}"`);
    }
    return null as ThisReturnType;
  }


  export type byCountryLocale<
    TCountryLocale extends CountryLocaleAnyCase,
    TDefault = never
  > = SettingsIndexedByCountryLocale<LocaleSettingsArray>[Lowercase<TCountryLocale>] | TDefault;

  export function byCountryLocale<
    TCountryLocale extends CountryLocaleAnyCase,
    const TThrowIfMissing extends boolean | undefined
  >(
    countryLocale: TCountryLocale,
    throwIfMissing?: TThrowIfMissing
  ): byCountryLocale<TCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>> {
    type ThisReturnType = byCountryLocale<TCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>;
    const countryLocaleLower = countryLocale.toLowerCase() as CountryLocale;
    const res = LocaleSettingsArray.find(el => el.CountryLocale === countryLocaleLower);

    if (res) {
      return res as ThisReturnType;
    }
    if (throwIfMissing) {
      throw new Error(`ERROR! Unexpected value for parameter 'countryLocale': ${countryLocale}`);
    }
    return null as ThisReturnType;
  }

  export type byExtendedCountryLocale<
    TExtendedCountryLocale extends
      | ExtendedLocaleCountry
      | Uppercase<ExtendedLocaleCountry>,
    TDefault = never,
    TSettingsObject extends SettingsIndexedByExtendedLocale = SettingsIndexedByExtendedLocale
    > = TSettingsObject[Lowercase<TExtendedCountryLocale>] | TDefault


  export function byExtendedCountryLocale<
    TExtendedCountryLocale extends
      | ExtendedLocaleCountry
      | Uppercase<ExtendedLocaleCountry>,
      const TThrowIfMissing extends boolean | undefined
    >(
      extendedCountryLocale: TExtendedCountryLocale,
      throwIfMissing?: TThrowIfMissing
    ): byExtendedCountryLocale<TExtendedCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>
  {
    type ThisReturnType = byExtendedCountryLocale<TExtendedCountryLocale, DefaultFromThrowIfMissing<TThrowIfMissing>>;
    const extendedCountryLocaleLower = extendedCountryLocale.toLowerCase() as ExtendedLocaleCountry;
    const res = SiteLocaleWithExtendedCountryLocaleArray.find((siteLocale: ISiteLocaleWithExtendedCountryLocales) => {
      return siteLocale.ExtendedCountryLocales.findIndex((extendedLoc: ICountryLocale) => (extendedLoc.CountryLocale === extendedCountryLocaleLower)) > -1;
    });
    if (res) {
      return res as ThisReturnType;
    }
    if (throwIfMissing) {
      throw new Error(`ERROR! Unexpected value for parameter 'extendedCountryLocale': ${extendedCountryLocale}`);
    }
    return null as ThisReturnType;
  }
}