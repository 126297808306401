import { SiteLocaleSettings } from "./locale-settings.js";
import { GetExtendedLocale, GetSiteLocale } from "./props/GetSiteLocaleBy.js";
export * from "./props/index.js";


const SiteLocaleFunctions = {
  getBySiteCountryCode: GetSiteLocale.bySiteCountryCode,
  getByLanguageCountryLocale: GetSiteLocale.byLanguageCountryLocale,
  getByCountryLocale: GetSiteLocale.byCountryLocale,
  getByExtendedCountryLocale: GetSiteLocale.byExtendedCountryLocale,
  getExtendedCountryLocale: GetExtendedLocale.byCountryLocale
};

type SiteLocaleFunctions = typeof SiteLocaleFunctions;


export const SiteLocale = {
  ...SiteLocaleSettings,
  ...SiteLocaleFunctions
} as const satisfies (SiteLocaleSettings & SiteLocaleFunctions);