import type { ExpandShallow } from "@mcwd/typescript-type-guards";
import type { ICountryLocale } from "./ICountryLocale.js";
import type { ILanguageCountryLocale } from "./ILanguageCountryLocale.js";

export type UrlPrefixType<TSiteCountryCode extends string> = [string] extends [TSiteCountryCode] ? string : ("us" extends TSiteCountryCode ? "" : `/${TSiteCountryCode}`);

export interface ISiteLocale<
  TSiteCountryCode extends string = string,
  TLanguageName extends string = string,
  TLanguageLocale extends string = string,
  TCountryName extends string = string,
  TCountryLocale extends string = string,
  TUrlPrefix extends UrlPrefixType<TSiteCountryCode> = UrlPrefixType<TSiteCountryCode>,
  TExtendedCountryLocales extends [] | [ICountryLocale, ...ICountryLocale[]] = [] | [ICountryLocale, ...ICountryLocale[]]
> extends ILanguageCountryLocale<TLanguageName, TLanguageLocale, TCountryName, TCountryLocale> {
  SiteCountryCode: TSiteCountryCode,
  SiteCountryCodeUppercase: Uppercase<TSiteCountryCode>,
  UrlPrefix: TUrlPrefix,
  ExtendedCountryLocales: TExtendedCountryLocales
}

export type ISiteLocaleWithExtendedCountryLocales<
  TSiteCountryCode extends string = string,
  TLanguageName extends string = string,
  TLanguageLocale extends string = string,
  TCountryName extends string = string,
  TCountryLocale extends string = string,
  TUrlPrefix extends UrlPrefixType<TSiteCountryCode> = UrlPrefixType<TSiteCountryCode>,
  TExtendedCountryLocales extends [ICountryLocale, ...ICountryLocale[]] = [ICountryLocale, ...ICountryLocale[]]
> = ISiteLocale<TSiteCountryCode, TLanguageName, TLanguageLocale, TCountryName, TCountryLocale, TUrlPrefix, TExtendedCountryLocales>;

export function isSiteLocaleWithExtendedCountryLocales(value: ISiteLocale): value is ISiteLocaleWithExtendedCountryLocales {
  return (value.ExtendedCountryLocales.length > 0);
}



export type ISiteLocaleKey = ExpandShallow<keyof ISiteLocale>;