import { isString } from "./primitive-types.js";

// The type definitions for this file are purposefully updated by hand in types.d.ts

export function isArray(value) {
    return Array.isArray(value);
}

export function isNotArray(value) {
    return isArray(value) === false;
}

export function isSingleItemArray(value) {
    return isArray(value) && value.length === 1;
}

export function isMultiItemArray(value) {
    return isArray(value) && value.length > 1;
}

export function isArrayOfType(value, typeCheckFunc) {
    return isArray(value) && value.every(typeCheckFunc) === true;
}
export function isStringArray(value) {
    return isArrayOfType(value, isString);
}