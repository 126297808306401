import { isNil, isNull, isUndefined, isObject, isString, isStringArray, type Nil } from "@mcwd/typescript-type-guards";
import type { ILogger } from "./ILogger.js";
import { checkLocalStorageAvailable } from "../check-local-storage-available.js";


export type TagsRecord<T extends (string | string[] | null) = string[]> = Record<string, T>;

function parseTagsObjFromLocalStorage(propName: string, localStorageTagsString: string): TagsRecord<string[] | null> | Nil  {
    try {
        const localStorageTags = JSON.parse(localStorageTagsString) as unknown;
        if (isObject(localStorageTags)) {
            const localStorageTagKeys = Object.keys(localStorageTags);
            if (localStorageTagKeys.length > 0 && localStorageTagKeys.every(k => (isStringArray(localStorageTags[k]) || isNull(localStorageTags[k])))) {
                return (localStorageTags as TagsRecord<string[] | null>);
            }
        }
    }
    catch {
        window.localStorage.removeItem(propName);
    }
    return null;
}
function readPropFromLocalStorage(propName: string): TagsRecord<string[] | null> | Nil {
    try {
        if (checkLocalStorageAvailable()) {
            const localStorageTagsString = window.localStorage.getItem(propName);
            if (isString(localStorageTagsString)) {
                return parseTagsObjFromLocalStorage(propName, localStorageTagsString);
            }
        }
    }
    catch { return null; }
    return null;
}

function saveValueToLocalStorage(propName: string, value: TagsRecord<string[] | null>, saveToLocalStorage: boolean) {
    try {
        if (saveToLocalStorage && Object.keys(value).length > 0 && checkLocalStorageAvailable()) {
            window.localStorage.setItem(propName, JSON.stringify(value));
        }
    }
    // eslint-disable-next-line no-empty
    catch { }
}

function removeValueFromLocalStorage(propName: string) {
    try {
        if (checkLocalStorageAvailable()) {
            window.localStorage.removeItem(propName);
        }
    }
    // eslint-disable-next-line no-empty
    catch { }
}

type MergeTagsFunction<T extends string[] | string | null> = (key: string, valOne: T | undefined, valTwo: T | undefined) => T | undefined;
function mergeTagsObjects<T extends string[] | string | null>(tagsObjOne: TagsRecord<T>, tagsObjTwo: TagsRecord<T>, mergeFunc: MergeTagsFunction<T>) {
    const allKeys = [... new Set([...Object.keys(tagsObjOne), ...Object.keys(tagsObjTwo)])];
    if (allKeys.length > 0) {
        const newTagsObj: TagsRecord<T> = {};
        for (const key of allKeys) {
            const propMergeRes = mergeFunc(key, tagsObjOne[key], tagsObjTwo[key]);
            if (isUndefined(propMergeRes) === false) {
                newTagsObj[key] = propMergeRes as Exclude<T, undefined>;
            }
        }
        return newTagsObj;
    }
    return {};
}

function mergeInitialWithLocalStorageValue(initialValue: TagsRecord<string[]>, localStorageVal: TagsRecord<string[] | null>, removeExtraTagsFromInitial: boolean) {
    return mergeTagsObjects<string[] | null>(initialValue, localStorageVal, (key, valOne, valTwo) => {
        if (isUndefined(valTwo) === false) {
            if (isStringArray(valOne) && isStringArray(valTwo)) {
                if (removeExtraTagsFromInitial) {
                    return valOne.filter(el => valTwo.includes(el));
                }
                else {
                    return [... new Set([...valOne, ...valTwo])];
                }
            }
            return valTwo as string[] | null;
        }
        return valOne as undefined | string[];
    });
}

function definePropOnWindow(propName: string, removeExtraTagsFromInitial: boolean) {
    const propDescriptor = Object.getOwnPropertyDescriptor(window, propName);
    const initialValue = ((isObject(propDescriptor) && propDescriptor.writable)
        ? window[propName]
        : {}) as TagsRecord<string[]>;

    if (isNil(propDescriptor) || propDescriptor.writable) {
        const localStorageVal = readPropFromLocalStorage(propName);
        const mergedValue: TagsRecord<string[] | null> = isObject(localStorageVal)
            ? mergeInitialWithLocalStorageValue(initialValue, localStorageVal, removeExtraTagsFromInitial)
            : initialValue;
        Object.defineProperty(window, propName, {
            value: { ...mergedValue },
            writable: false,
            configurable: false,
            enumerable: true
        });
    }
}

definePropOnWindow("__loggerFilteredTags", false);
definePropOnWindow("__loggerFilteredTagsOverride", true);

type WindowWithLoggerFilters = typeof window & {
    __loggerFilteredTags: TagsRecord<string[] | null>,
    __loggerFilteredTagsOverride: TagsRecord<string[] | null>,
    __loggerFilter: {
        getLoggerFilter: typeof getLoggerFilter, // eslint-disable-line no-use-before-define
        addLoggerFilter: typeof addLoggerFilter, // eslint-disable-line no-use-before-define
        removeLoggerFilter: typeof removeLoggerFilter // eslint-disable-line no-use-before-define
        getLoggerFilterOverride: typeof getLoggerFilterOverride, // eslint-disable-line no-use-before-define
        addLoggerFilterOverride: typeof addLoggerFilterOverride, // eslint-disable-line no-use-before-define
        removeLoggerFilterOverride: typeof removeLoggerFilterOverride // eslint-disable-line no-use-before-define
    }
}

function parseTagsObj<T extends string | string[] | null>(obj: TagsRecord<T>): TagsRecord<string[] | null> {
    const objKeys = Object.keys(obj);
    for (const k of objKeys) {
        if (isString(obj[k])) {
            obj[k] = [obj[k]] as T;
        }
        if ((isStringArray(obj[k]) || isNull(obj[k])) === false) {
            console.error(`Tags obj array for prop "${k}" is not null or a string array! Removing values!`, obj[k]);
            delete obj[k];
        }
    }
    return obj as TagsRecord<string[] | null>;
}

function addTagsToTagsObj(obj: TagsRecord<string | string[] | null>, newTags: TagsRecord<string | string[] | null>) {
    const newKeys = Object.keys(newTags);
    for (const k of newKeys) {
        if (isString(newTags[k])) {
            newTags[k] = [newTags[k]] as string[];
        }
        if (newTags[k] === null) {
            obj[k] = null;
        }
        else {
            if (isString(obj[k])) {
                obj[k] = [obj[k]] as string[];
            }
            obj[k] ??= [];
            const tagsToAdd = (newTags[k] as string[]).filter(t => (obj[k] as string[]).includes(t) === false);
            obj[k] = [...(obj[k] as string[]), ...tagsToAdd];
        }
    }
}

function removeTagsFromTagsObj(obj: TagsRecord<null | string | string[]>, tagsToRemove: string | TagsRecord<null | string | string[]>) {
    if (isString(tagsToRemove)) {
        delete obj[tagsToRemove];
    }
    else {
        const keys = Object.keys(tagsToRemove);
        for (const k of keys) {
            const tagsToRemoveVal = [tagsToRemove[k]].flat();
            if (isString(obj[k])) {
                obj[k] = [obj[k] as string];
            }
            if (isNull(obj[k])) {
                if (isNull(tagsToRemove[k])) {
                    delete obj[k];
                }
                continue;
            }
            obj[k] = (obj[k] as string[]).filter(item => (tagsToRemoveVal.includes(item) === false));
        }
    }
}

export function getLoggerFilter(): TagsRecord<string[] | null> {
    const dTags = (window as WindowWithLoggerFilters).__loggerFilteredTags;
    return { ...parseTagsObj(dTags) };
}

export function addLoggerFilter(filteredTags: TagsRecord<null | string | string[]>, saveToLocalStorage: boolean) {
    const dTags = (window as WindowWithLoggerFilters).__loggerFilteredTags;
    addTagsToTagsObj(dTags, filteredTags);
    saveValueToLocalStorage("__loggerFilteredTags", dTags, saveToLocalStorage);
}

export function removeLoggerFilter(tagName: string): void;
export function removeLoggerFilter(tagsToRemove: TagsRecord<string | string[] | null>): void;
export function removeLoggerFilter(tagsToRemove: string | TagsRecord<string | string[] | null>): void;
export function removeLoggerFilter(tagsToRemove: string | TagsRecord<string | string[] | null>): void {
    const dTags = (window as WindowWithLoggerFilters).__loggerFilteredTags;
    removeTagsFromTagsObj(dTags, tagsToRemove);
    saveValueToLocalStorage("__loggerFilteredTags", dTags, true);
}

export function getLoggerFilterOverride(): TagsRecord<string[] | null> {
    const overrides = (window as WindowWithLoggerFilters).__loggerFilteredTagsOverride;
    return { ...parseTagsObj(overrides) } as TagsRecord<string[] | null>;
}

export function addLoggerFilterOverride(filteredTagsOverride: TagsRecord<string | string[] | null>, saveToLocalStorage: boolean) {
    const overrides = (window as WindowWithLoggerFilters).__loggerFilteredTagsOverride;
    addTagsToTagsObj(overrides, filteredTagsOverride);
    saveValueToLocalStorage("__loggerFilteredTagsOverride", overrides, saveToLocalStorage);
}

export function removeLoggerFilterOverride(tagName: string);
export function removeLoggerFilterOverride(tagsToRemove: TagsRecord<string | string[] | null>);
export function removeLoggerFilterOverride(tagsToRemove: string | TagsRecord<string | string[] | null>);
export function removeLoggerFilterOverride(tagsToRemove: string | TagsRecord<string | string[] | null>) {
    const overrides = (window as WindowWithLoggerFilters).__loggerFilteredTagsOverride;
    removeTagsFromTagsObj(overrides, tagsToRemove);
    saveValueToLocalStorage("__loggerFilteredTagsOverride", overrides, true);
}

export function resetLoggerFilter() {
    const dTags = (window as WindowWithLoggerFilters).__loggerFilteredTags;
    const keys = Object.keys(dTags);

    for (const k of keys) {
        delete dTags[k];
    }
    removeValueFromLocalStorage("__loggerFilteredTags");

    const overrides = (window as WindowWithLoggerFilters).__loggerFilteredTagsOverride;
    const oKeys = Object.keys(overrides);
    for (const k of oKeys) {
        delete overrides[k];
    }
    removeValueFromLocalStorage("__loggerFilteredTagsOverride");
}

export function checkLoggerIsFiltered(logger: ILogger):boolean {
    const tags = logger.tags;
    const filteredTags = getLoggerFilter();
    const overrides = getLoggerFilterOverride();
    return Object.keys(tags).some(k => {
        const tagVal: string[] = [tags[k]].flat();
        const filteredVal: string[] = filteredTags[k] ?? [];
        const overrideVal: string[] = (overrides[k]) ?? [];
        if (overrideVal.includes("*") || tagVal.some(t => overrideVal.includes(t))) {
            return false;
        }
        return filteredVal.includes("*") || tagVal.some(t => filteredVal.includes(t));
    });
}

(window as WindowWithLoggerFilters).__loggerFilter = Object.freeze({
    getLoggerFilter,
    addLoggerFilter: (filteredTags: TagsRecord<null | string | string[]>, saveToLocalStorage = true) => addLoggerFilter(filteredTags, saveToLocalStorage),
    removeLoggerFilter,
    getLoggerFilterOverride,
    addLoggerFilterOverride: (filteredTagsOverride: TagsRecord<null | string | string[]>, saveToLocalStorage = true) => addLoggerFilterOverride(filteredTagsOverride, saveToLocalStorage),
    removeLoggerFilterOverride
});