import type { Nil } from "@mcwd/typescript-type-guards";
import { getMcUrlData, type McUrlData } from "./McUrlData.js";

export interface PageDataSettings {
  Server: string;
  UrlPrefixesToRemove: string[];
  SfTemplateName: string | Nil;
  UrlData: McUrlData
}

/** Get variable from window object so we have a singleton.  Otherwise each time a split bundle is imported, another version is created  */
export const PageDataSettings: PageDataSettings = ((window as any).PageDataSettings as Nil | PageDataSettings) ??  {
  Server: "",
  UrlPrefixesToRemove: [],
  SfTemplateName: window.AppState.SfTemplateName,
  UrlData: {} as McUrlData
};

/** Save to window object so we have a singleton.  Otherwise each time a split bundle is imported, another version is created  */
if (("PageDataSettings" in window) === false) {
  Object.defineProperty(window, "PageDataSettings", { value: PageDataSettings, writable: false });
}


export function initPageDataSettings({ server, urlPrefixesToRemove }: { server: string, urlPrefixesToRemove: string[] }) {
  PageDataSettings.Server = server;
  PageDataSettings.UrlPrefixesToRemove = urlPrefixesToRemove;
  PageDataSettings.UrlData = getMcUrlData({urlPrefixesToRemove});
}